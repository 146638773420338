<template>
	<div>
		<h2>{{ $t('monte.planning_monte') }}</h2>
		<div class="row mb-4">
			<div class="col-12">
				<div class="box">
					<h4 class="mb-4"> {{ $t('monte.lieu_residence') }} </h4>
					<e-select
						v-model="residence_selected"
		                :options="residences"
		                :searchable="true"
		                label="lieu_label"
		                :loading="loadResidence"
					>	
						<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>

					<b-form-checkbox id="planning_default" v-model="planning_default"> <label for="planning_default">{{ $t("monte.default_season") }}</label></b-form-checkbox>
				</div>
			</div>
		</div>
		<div v-if="residence_selected.lieu_id" class="row">
			<div class="col-12 col-md-6">
				<div class="box h-100">
					<h4 class="mb-4"> {{ $t('monte.choix_creneaux') }} </h4>
               		<draggable class="list-group list-group-left" :list="creneaux" v-bind="dragOptionsSelectedColumns" group="colonne">
               			<div class="list-group-item align-items-center" v-for="(creneau, index) in creneaux" :key="index">
							<div class="monte_creneaux">
								<div class="form-row align-items-center">
									<div class="col-lg-12 col-xl-auto mr-auto">
										<label class="mb-0" :for="'creneau_' + index">{{ $t('monte.creneau') }} {{ index+1 }}</label>
									</div>
									<div class="col">
							            <input
							                class="form-control"
							                v-model="creneau.start"
							                type="text"
							                required
							            />
									</div>
									<div class="col-auto">
							            {{ $t('global.a') }}
							        </div>
									<div class="col">
							            <input
							                class="form-control"
							                v-model="creneau.end"
							                type="text"
							                required
							            />
							        </div>
									<div class="col-auto">
							            <b-button @click="deleteCreneau(index)" variant="secondary">
											<font-awesome-icon :icon="['fal', 'trash-alt']" />
										</b-button>
									</div>
									<div class="col-auto ml-auto">
										<div class="btn btn-secondary ml-auto order-md-last">
							                <font-awesome-icon :icon="['fal', 'sort-alt']" />
							            </div>
							        </div>
								</div>
							</div>

						</div>
					</draggable>
					<div class="text-center mt-4">
						<button class="btn btn-secondary rounded-pill mr-3" @click="addCreneau">
							<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('global.ajouter') }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="box h-100">
					<h4 class="mb-4"> {{ $t('monte.choix_etalon') }} </h4>
               		<draggable class="list-group list-group-left" :list="stallions" v-bind="dragOptionsSelectedColumns" group="colonne">
               			<div class="list-group-item align-items-center" v-for="(stallion, index) in stallions" :key="index">
							<div class="monte_creneaux">
								<div class="form-row align-items-center">
									<div class="col">
							            <e-select
											v-model="stallion.horse.planninghorse_stallion"
							                :options="stallion_dispo"
							                :searchable="true"
							                label="horse_nom"
										>	
											<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
											<template slot="noOptions">{{ $t('global.list_empty') }}</template>
										</e-select>
									</div>
									<div class="col-auto">
							            <b-button @click="deleteEtalon(index)" variant="secondary">
											<font-awesome-icon :icon="['fal', 'trash-alt']" />
										</b-button>
									</div>
									<div class="col-auto ml-auto">
										<div class="btn btn-secondary ml-auto order-md-last">
							                <font-awesome-icon :icon="['fal', 'sort-alt']" />
							            </div>
							        </div>
								</div>
							</div>
               			</div>
               		</draggable>
					<!-- <template v-for="(stallion, index) in stallions" :key="index">
						<ConfigEtalon
							:key="stallion.index"
							:ref="'etalon_' + index"
							:index="index + 1" 
							:delete_stallion.sync="delete_stallion"
							:stallion="stallion_dispo"
							:horse="stallion.horse"
						/>
						
					</template> -->
					<div class="text-center mt-4">
						<button class="btn btn-secondary rounded-pill mr-3" @click="addStallion">
							<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('global.ajouter') }}
						</button>
					</div>
				</div>
			</div>
        </div>
		<div class="text-center">
            <b-button pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Lieux from "@/mixins/Lieux.js"
	import Common from '@/assets/js/common'
	import _isEmpty from 'lodash/isEmpty'
    import draggable from 'vuedraggable'

	export default {
		name: 'PlanningMonte',
		props: ['season_id'],
		mixins: [Navigation, PlanningMonte, Lieux],
		data () {
			return {
				processing: false,
				creneaux: [],
				stallions: [],
				delete_creneau: null,
				delete_stallion: null,
				stallion_dispo: [],

				loadResidence: false,
				residences: [],
				residence_selected: {},
				planning_default: false,

                dragOptionsSelectedColumns: {
                    animation: 200
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadResidence = true
				this.residences = await this.getResidences()
				this.loadResidence = false

				this.stallion_dispo = await this.getHorsesStallion(false)

				const residence = await this.getResidencePlanningDefault(this.season_id, "main")

				if(residence) {
					this.residence_selected = this.residences.find(lieu => lieu.lieu_id == residence.planning_lieu)
				}
			},

			async loadPlanning() {
				this.creneaux = []
				this.stallions = []
				this.planning_default = false

				const planning = await this.getPlanningInfo(this.season_id, this.residence_selected.lieu_id, 'main')
				if(!_isEmpty(planning)) {

					planning.planning_schedule.forEach(schedule => {
						this.creneaux.push({
							index: Common.getRandomInt(99999),
							id: schedule.schedule_id,
							start: schedule.schedule_start,
							end: schedule.schedule_end,
							order: schedule.schedule_order
						})
					})

					planning.planning_horse.forEach(horse => {
						this.stallions.push({
							index: Common.getRandomInt(99999),
							horse: horse,
						})
					})
					this.planning_default = planning.planning_default
				}
			},

			async checkForm() {
				this.processing = true

				let error = false
				let creneaux = [], stallion = []

				for(let i in this.creneaux) {
					if(!this.creneaux[i].start || !this.creneaux[i].end) {
						error = true
					}
					this.creneaux[i].order = i
					creneaux.push(this.creneaux[i])
				}

				for(let i in this.stallions) {
					this.stallions[i].horse.planninghorse_stallion.order = i
					stallion.push(this.stallions[i].horse.planninghorse_stallion)
				}

				if(!this.planning_default) 
					this.planning_default = false

				if(error) {
					this.failureToast("monte.planning_bad_config")
					this.processing = false
					return 
				}

				const res = await this.saveParametrePlanningMain(this.season_id, this.residence_selected.lieu_id, creneaux, stallion, this.planning_default)
				this.processing = false
				if(res) {
					this.successToast("toast.info_modif_succes")
				}
			},

			addCreneau() {
				this.creneaux.push({index:Common.getRandomInt(99999)})
			},

			addStallion() {
				const stallion_taken = []
				for(let i in this.stallions) {
					stallion_taken.push(this.stallions[i].horse.planninghorse_stallion.horse_id)
				}
				
				this.stallion_dispo = this.stallion_dispo.filter(stallion => !stallion_taken.includes(stallion.horse_id))

				this.stallions.push({index:Common.getRandomInt(99999), horse: {planninghorse_stallion: {}}})
			},

			deleteCreneau(index) {
				this.delete_creneau = index
			},

			deleteEtalon(index) {
				this.delete_stallion = index
			}
		},

		watch: {
			delete_creneau (index){
				if(index > -1) 
					this.creneaux.splice(index, 1)
				this.delete_creneau = -1
			},
			delete_stallion (index){
				if(index > -1) 
					this.stallions.splice(index, 1)
				this.delete_stallion = -1
			},
			residence_selected () {
				this.loadPlanning()
			}
		},

		components: {
            ConfigCreneaux: () => import('@/components/Saison/ConfigCreneaux'),
            ConfigEtalon: () => import('@/components/Saison/ConfigEtalon'),
            draggable
		}
	}

</script>